import { Component, input, output, HostBinding, inject } from '@angular/core';
import User from '../../models/user.model';
import { FRIENDACTION, FRIENDSTATUS } from '../../../app.constants';
import { UsersService } from '../../services/users.service';
import { SimplePromptModalComponent } from '../simple-prompt-modal/simple-prompt-modal.component';
import type { ISimplePromptData } from '../simple-prompt-modal/simple-prompt-modal.interfaces';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { SubscribableBaseComponent } from '../subscribable-base/subscribable-base.component';
import { AlertService } from '../../services/alert.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';


@Component({
	selector: 'dflgr-friend-actions',
	standalone: true,
	imports: [MatButtonModule, MatIconModule],
	templateUrl: './friend-actions.component.html',
	styleUrls: ['./friend-actions.component.scss']
})
export class FriendActionsComponent extends SubscribableBaseComponent {
	readonly #modalHandler = inject(MatDialog);
	readonly #alertService = inject(AlertService);
	readonly #userProvider = inject(UsersService);

	readonly friend = input.required<User>();
	readonly isFriendRequest = input<boolean>();
	@HostBinding('class.vertical')
	get verticalDisplay(): boolean {
		return this.vertical();
	}
	readonly vertical = input(false);
	readonly statusChanged = output<FRIENDSTATUS>();
	readonly ACTION = FRIENDACTION;

	async updateFriendStatus(action: FRIENDACTION) {
		try {
			const userAction = await new Promise<FRIENDACTION>((resolve, reject) => {
				if (action === FRIENDACTION.REMOVEFRIEND && !this.isFriendRequest && !this.friend().isPending) {
					this.#modalHandler.open<SimplePromptModalComponent<boolean>, ISimplePromptData<boolean>, boolean>(SimplePromptModalComponent, {
						data: {
							title: 'Confirm friend removal',
							text: `Are you sure you want to unfriend ${this.friend().username}?`,
							actions: [{code: true, label: 'Confirm'}, { code: false, label: 'Cancel' }]
						}
					}).afterClosed()
						.subscribe(confirmed => {
							confirmed ? resolve(action) : reject();
						});

				} else {
					resolve(action);
				}
			});

			this.#userProvider.updateFriendStatus(this.friend(), userAction)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((newStatus: FRIENDSTATUS) => {
					this.friend().friendStatus = newStatus;
					this.statusChanged.emit(newStatus);
				}, () => {
					this.#alertService.publish({
						msg: 'Could not proceed!\nTry again later',
						action: 'Damn'
					});
				});

		} catch (e) {
			// No action if modal is canceled or discarded
		}
	}

}
