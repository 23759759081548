<mat-card>
	<mat-card-header class="card-header">
		<dflgr-user-avatar matCardAvatar class="avatar" [user]="user()" [link]="true"></dflgr-user-avatar>
		<mat-card-title class="name moderate-card-title">
			<a [routerLink]="userLink">
				<span>@if (user()?.hasName) { {{user()?.name}} {{user()?.surname}} } @else { {{user()?.username}} }</span>
			</a>
		</mat-card-title>
		<ng-container ngProjectAs="mat-card-subtitle">
			@if (user()?.hasName) {
				<mat-card-subtitle class="username"><a [routerLink]="userLink">{{user()?.username}}</a></mat-card-subtitle>
			}
		</ng-container>
		<div class="h-spacer"></div>
		<div class="extra">
			@if (userIsGroupAdmin) {
				<span class="chip-item group-admin"><mat-icon>star</mat-icon> Admin</span>
			}
			@if (user()?.isFriend) {
				<span class="chip-item user-friend">Friend</span>
			}
			@if (user()?.isBlocked) {
				<span class="chip-item user-blocked">Blocked</span>
			}
			@if (user()?.isPending) {
				<span class="chip-item user-pending">Pending</span>
			}
		</div>
		<div class="card-flags">
			@if (user()?.userFlag) {
				<dflgr-country-flag [country]="user().userFlag.country" [showName]="false"/>
			}
		</div>
		@if (group() && (selfIsGroupAdmin || userIsSelf)) {
			<dflgr-extended-menu [letContent]="true">
				@if (selfIsGroupAdmin && !userIsSelf) {
					<button mat-menu-item (click)="makeAdmin.emit();">
						<mat-icon>star_border</mat-icon>
						<span>Make admin</span>
					</button>
				}
				<button mat-menu-item class="ext-item" [class.danger]="userIsSelf" (click)="removeFromGroup.emit()">
					<mat-icon>@if (userIsSelf) { directions_run } @else { delete }</mat-icon>
					<span>@if (userIsSelf) { Leave } @else { Remove from }</span> group
				</button>
			</dflgr-extended-menu>
		}
		@if (user()?.isFriend) {
			<dflgr-extended-menu [letContent]="true">
				<dflgr-friend-actions
					[friend]="user()"
					[vertical]="true"
					[isFriendRequest]="isFriendRequest()"
					(statusChanged)="statusChanged.emit()"/>
			</dflgr-extended-menu>
		}
	</mat-card-header>
	<mat-card-content class="card-content">
		<div class="user-details">
			<a [routerLink]="user()?.country1.link">
				<dflgr-country-flag [country]="user()?.country1" [showName]="false"/>
			</a>
			@if (user()?.country2) {
				<a [routerLink]="user()?.country2.link">
					<dflgr-country-flag [country]="user()?.country2" [showName]="false"/>
				</a>
			}
			<!-- span class="gender" *ngIf="showFriendActions">{{user?.genderIcon}}</span -->
		</div>
		<div class="card-main">
			@if (user()?.userFlag) {
				<div class="user-flag">
					<div>Times: <a [routerLink]="getUserCountryLink(user()?.userFlag.country)">&times;{{user()?.userFlag.times}}</a></div>
					@if (user()?.userFlag.times > 1) {
						<div>First: {{user()?.userFlag.first | date}}</div>
						<div>Last: {{user()?.userFlag.last | date}}</div>
					} @else {
						<div>On {{user()?.userFlag.first | date}}</div>
					}
				</div>
			}
			@if (showFriendActions() && user()?.countriesList) {
				<div>
					<span>Flags: {{user()?.countriesList.length}}</span>
				</div>
			}
		</div>
	</mat-card-content>
	@if (showFriendActions() && !user()?.isFriend) {
		<mat-card-actions>
			<dflgr-friend-actions
				[friend]="user()"
				[isFriendRequest]="isFriendRequest()"
				(statusChanged)="statusChanged.emit()"/>
		</mat-card-actions>
	}
</mat-card>
